<template>
  <div class="lead-card d-flex shadow-sm rounded min-w-0" :class="[selected ? 'selected' : 'bg-white']">
    <div class="pt-3 pl-3">
      <hs-checkbox v-model="selected" @input="$event ? $emit('selected', lead) : $emit('deselected', lead)" />
    </div>
    <div class="d-flex flex-column pt-3 pl-2 pl-sm-4 pr-1 flex-1 min-w-0">
      <h5 class="text-capitalize font-weight-bold mb-3 lh-150">{{ lead.name }}</h5>
      <ul class="data-list list-unstyled d-flex flex-column">
        <li v-for="(data, index) in leadData" :key="index" class="d-flex align-items-center mt-2">
          <hs-icon :icon="data.icon" class="mr-2" />
          <p class="d-inline-block text-truncate mb-0 lh-150">{{ data.value }}</p>
        </li>
      </ul>
    </div>
    <hs-button
      id="btn-details"
      class="border-0 shadow-sm h-100 d-flex align-items-center justify-content-center rounded-sm"
      variant="link"
      @click="$emit('click', lead)"
    >
      <hs-icon icon="chevron-right" />
    </hs-button>
  </div>
</template>

<script>
export default {
  props: {
    lead: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: false,
    };
  },
  computed: {
    leadData() {
      return [
        {
          icon: 'envelope',
          value: this.lead.email,
        },
        {
          icon: 'phone',
          value: this.lead.phone || '-',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.min-w-0 {
  min-width: 0;
}

#btn-details {
  max-width: 30px;
}

.lead-card {
  width: 330px;
}

.data-list {
  color: $grey-30;
}

.selected {
  background: $light-blue;
  outline: 1px solid $purple-dark;
}
</style>
