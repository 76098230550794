<template>
  <div class="tlw-flex tlw-flex-col tlw-items-center tlw-pt-6 tlw-pb-12 md:tlw-pb-6">
    <h3 class="tlw-w-3/4 tlw-text-gray-900 tlw-text-center tlw-font-bold tlw-mb-12 md:tlw-pb-2">
      {{ $t('sparkfunnels.lead_manager.leads_import.step_upload_file.title') }}
    </h3>
    <div class="tlw-w-full">
      <custom-collapse
        :collapsed="true"
        :title="$t('sparkfunnels.lead_manager.leads_import.step_upload_file.collapse.steps.download_file')"
      >
        <sample-file />
      </custom-collapse>
      <custom-collapse
        :title="$t('sparkfunnels.lead_manager.leads_import.step_upload_file.collapse.steps.example_video')"
      >
        <sample-video />
      </custom-collapse>
      <custom-collapse
        v-if="isAdmin"
        data-testid="admin-upload-collapse"
        :title="$t('sparkfunnels.lead_manager.leads_import.step_upload_file.collapse.steps.upload_file')"
      >
        <drop-file @uploaded="uploaded" :loadedFile="file" />
      </custom-collapse>
      <custom-collapse
        v-else
        data-testid="user-upload-instructions-collapse"
        :title="$t('sparkfunnels.lead_manager.leads_import.step_upload_file.collapse.steps.request_upload_file')"
        @opened="toggleChatImportButton"
      >
        <upload-instructions />
      </custom-collapse>
    </div>
    <div
      class="w-100 div-buttons d-flex flex-column flex-md-row-reverse justify-content-center align-items-center pt-4"
    >
      <hs-button
        v-if="isAdmin && file"
        id="confirm-button"
        class="mb-2 mb-md-0 custom-button"
        variant="primary"
        @click="$emit('proceed')"
      >
        {{ $t('sparkfunnels.lead_manager.leads_import.step_upload_file.buttons.confirm') }}
      </hs-button>
      <hs-button
        v-else-if="!isAdmin && isChatFeatureEnabled && showChatImportButton"
        class="tlw-mb-2 md:tlw-mb-0"
        variant="link"
        @click="openChat()"
      >
        {{ $t('sparkfunnels.lead_manager.leads_import.step_upload_file.buttons.request_import') }}
      </hs-button>

      <hs-button
        v-if="!showChatImportButton"
        id="cancel-button"
        class="mr-md-2 custom-button"
        variant="link"
        @click="$emit('recede')"
      >
        {{ $t('sparkfunnels.lead_manager.leads_import.step_upload_file.buttons.cancel') }}
      </hs-button>
    </div>
  </div>
</template>

<script>
import CustomCollapse from './CustomCollapse';
import SampleFile from './SampleFile';
import SampleVideo from './SampleVideo';
import DropFile from './DropFile';
import { mapState } from 'vuex';
import ZendeskService from '@/services/zendesk';

export default {
  components: {
    CustomCollapse,
    DropFile,
    SampleFile,
    SampleVideo,
    UploadInstructions: () => import('./UploadInstructions'),
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      file: this.data.file,
      showChatImportButton: false,
    };
  },
  methods: {
    uploaded(file) {
      this.file = file;
      this.$emit('change', { file });
    },
    openChat() {
      ZendeskService.openOnChat();
      this.$bvModal.hide('leads-import-modal');
    },
    toggleChatImportButton(uploadInstructionVisible) {
      this.showChatImportButton = uploadInstructionVisible;
    },
  },
  computed: {
    ...mapState('auth', ['loggedUser']),
    isAdmin() {
      return this.loggedUser.email === this.$t('login.form.fields.email.admin');
    },
    isChatFeatureEnabled() {
      return ZendeskService.alreadyInitialized();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/_custom_button.scss';

@include div-custom-buttons;
</style>
