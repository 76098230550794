<template>
  <hsSidebar :opened="!!clickedLead" class="position-relative">
    <template v-slot:content>
      <PageHeader
        :title="$t('sparkfunnels.lead_manager.list.page-header.title')"
        :subTitle="
          $t('sparkfunnels.lead_manager.list.page-header.sub-title', {
            total: totalLeads,
          })
        "
        backText="Voltar para funis"
        backRoute="FunnelsList"
        :backOnlyMobile="false"
      >
        <template #actions>
          <BtnImport :disabled="isLoading || (theAmount >= theLimite && theLimite != 0)" />
        </template>
      </PageHeader>

      <section v-if="isLoading">
        <hsLoading class="my-5" />
      </section>
      <section v-else>
        <div class="h-100 d-flex flex-column py-4 px-5">
          <AutoPilotBanner
            v-if="canAutomaticPilot"
            :description="$t('auto-pilot-banner.leads.description')"
            icon="cog"
            selected-model="interactWithLeads"
            class="auto-pilot-banner"
          />

          <div class="tlw-flex flex-lg-nowrap flex-md-nowrap flex-wrap mb-3">
            <LeadCards :linkForLeads="false" title="Meus leads" :theAmount="theAmount" :theLimite="theLimite" />
            <CardPlan
              :title="$t('my-account.views.plan.card.title')"
              :subTitle="$t('my-account.views.plan.card.description')"
              v-if="theLimite"
            />
          </div>
          <div>
            <div class="mb-5 d-flex flex-column flex-md-row align-items-md-end">
              <hs-group class="col-md-4 p-0 mb-0 mb-4 mb-md-0">
                <label class="font-weight-bold mb-0" for="query">
                  {{ $t('sparkfunnels.lead_manager.list.search.label') }}
                </label>
                <hs-input
                  id="query"
                  :placeholder="$t('sparkfunnels.lead_manager.list.search.placeholder')"
                  @input="searchLeads"
                >
                </hs-input>
              </hs-group>
              <div v-if="selectedLeads.length" class="d-flex align-items-center ml-md-4">
                <hs-button id="delete-leads" variant="cherry mr-2" @click="removeLeads">
                  <hs-icon icon="trash-alt" />
                </hs-button>
                <p class="mb-0">{{ $tc('sparkfunnels.lead_manager.list.selected_leads', selectedLeads.length) }}</p>
              </div>
            </div>
            <hsLoading v-if="isLoading" />
            <template v-else-if="leads.length">
              <Table
                ref="table"
                class="d-none d-lg-block"
                :items="leads"
                @selected="leads => (selectedLeads = leads)"
                @clicked="lead => (clickedLead = clickedLead == lead ? null : lead)"
                @delete="
                  ({ id }) => {
                    leadIdToDelete = id;
                    $bvModal.show(deleteLeadModalId);
                  }
                "
                :loading="loading"
              />
              <div class="d-flex flex-wrap d-lg-none">
                <LeadCard
                  v-for="(lead, index) in leads"
                  :lead="lead"
                  :key="index"
                  class="mb-3 mr-sm-3"
                  @click="lead => (clickedLead = clickedLead == lead ? null : lead)"
                  @selected="lead => selectedLeads.push(lead)"
                  @deselected="lead => (selectedLeads = selectedLeads.filter(l => l.id !== lead.id))"
                />
              </div>
            </template>
            <template v-else-if="!leads.length && query">
              <div class="no-results d-flex flex-column mx-auto mt-5 pt-5 align-items-center text-center">
                <b-img-lazy
                  :src="require('@/assets/images/SparkFunnels/LeadManager/astronaut.svg')"
                  class="mb-4"
                  :alt="$t('sparkfunnels.lead_manager.list.no_results.img_alt')"
                />
                <p class="font-weight-bold h4">{{ $t('sparkfunnels.lead_manager.list.no_results.title') }}</p>
                <p>{{ $t('sparkfunnels.lead_manager.list.no_results.description') }}</p>
              </div>
            </template>
            <template v-else>
              <p class="w-full text-center font-weight-bold" id="empty">
                {{ $t('sparkfunnels.lead_manager.list.empty') }}
              </p>
            </template>
          </div>
          <hs-pagination
            v-if="totalRows > perPage && !isLoading"
            v-model="currentPage"
            :total-rows="totalRows"
            :go-page-text="$t('sparkfunnels.lead_manager.list.go_page_text')"
            :per-page="perPage"
            align="center"
            class="pagination"
            :disabled="loading === 'paging'"
            @change="changePage($event)"
          ></hs-pagination>
        </div>
      </section>
      <hsConfirmModal
        id="delete-lead-modal"
        icon="trash-alt"
        variant="cherry"
        :title="$t('sparkfunnels.lead_manager.list.delete_lead_modal.ok_title')"
        :customTitle="$t('sparkfunnels.lead_manager.list.delete_lead_modal.title')"
        :description="$t('sparkfunnels.lead_manager.list.delete_lead_modal.description')"
        :ok-title="$t('sparkfunnels.lead_manager.list.delete_lead_modal.ok_title')"
        :cancel-title="$t('sparkfunnels.lead_manager.list.delete_lead_modal.cancel_title')"
        :verificationText="$t('sparkfunnels.lead_manager.list.delete_lead_modal.verification_text')"
        @ok="removeLead"
      />
    </template>
    <template v-slot:sidebar>
      <LeadDetails
        :lead="clickedLead"
        @close="() => (clickedLead = null)"
        @delete="
          ({ id }) => {
            leadIdToDelete = id;
            $bvModal.show(deleteLeadModalId);
          }
        "
      />
    </template>
  </hsSidebar>
</template>

<script>
import { hsLoading, hsConfirmModal } from '@/components';
import { mapActions, mapState } from 'vuex';
import hsPageTitle from '@/components/_structures/PageTitle';
import Table from './components/Table';
import ToastHelper from '@/shared/helpers/toast';
import LeadDetails from './components/Details';
import { hsSidebar } from '@/components';
import LeadCard from './components/Card';
import BtnImport from './components/Banner';
import debounce from 'lodash.debounce';
import Confirm from '@/shared/mixins/Confirm';
import TrackingHelper from '@/shared/helpers/tracking';
import { analyticsService } from '@/services';
import PageHeader from '@/components/_structures/PageHeader.vue';
import LeadCards from '../../Leads/LeadCard/LeadCard.vue';
import CardPlan from '../../Funnels/List/components/CardPlan.vue';
import heimdallService from '@/services/heimdall/newPlans';

export default {
  mixins: [Confirm],
  components: {
    hsLoading,
    Table,
    hsSidebar,
    LeadDetails,
    LeadCard,
    hsConfirmModal,
    PageHeader,
    LeadCards,
    CardPlan,
    BtnImport,
    AutoPilotBanner: () => import('@/components/AutoPilotBanner.vue'),
  },
  computed: {
    ...mapState('lead', {
      leads: state => state.items,
    }),
    ...mapState('school', {
      selectedSchool: state => state.selectedSchool,
    }),
    canAutomaticPilot() {
      return this.$ACL.canAutomaticPilotPage();
    },
  },
  watch: {
    currentPage(newPage, oldPage) {
      if (newPage != oldPage && newPage <= this.totalPages) {
        this.fetchLeads();
      }
    },
  },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      perPage: 8,
      totalPages: 0,
      totalRows: 0,
      leadIdToDelete: null,
      deleteLeadModalId: 'delete-lead-modal',
      query: null,
      totalLeads: 0,
      clickedLead: null,
      selectedLeads: [],
      theAmount: 0,
      theLimite: 0,
      premiumModalId: 'premium-modal',
      loading: '',
    };
  },
  methods: {
    ...mapActions('school', ['updateSchool']),
    ...mapActions('lead', ['getLeads', 'deleteLeads']),
    ...mapActions('funnel', ['getFunnelsLimit']),
    fetchLeads() {
      const params = {
        perPage: this.perPage,
        currentPage: this.currentPage,
        query: this.query,
      };
      this.getLeads(params)
        .then(res => {
          this.totalPages = res.total_pages;
          this.totalRows = res.total_count;
          this.totalLeads = this.totalRows > this.totalLeads ? this.totalRows : this.totalLeads;
        })
        .catch(() => ToastHelper.dangerMessage(this.$t('sparkfunnels.lead_manager.list.actions.fetch_leads.error')))
        .finally(() => {
          this.isLoading = false;
          this.loading = '';
        });
    },
    removeLead() {
      this.loading = 'removing';
      if (this.leadIdToDelete) {
        this.deleteLeads([this.leadIdToDelete])
          .then(() => {
            ToastHelper.successMessage(this.$t('sparkfunnels.lead_manager.list.actions.delete_lead.success'));
            this.totalRows--;
            this.clearSelection();
            TrackingHelper.trackLeadsRemoval(1, false);
            this.showLeadCard();
          })
          .catch(() => ToastHelper.dangerMessage(this.$t('sparkfunnels.lead_manager.list.actions.delete_lead.error')));
      }
      this.loading = '';
    },
    async removeLeads() {
      this.loading = 'removing';
      const leadsIds = this.selectedLeads.map(lead => lead.id);
      if (leadsIds) {
        const shouldDelete = await this.showConfirmBox({
          okTitle: this.$tc(
            'sparkfunnels.lead_manager.list.actions.delete_leads.confirm_modal.ok_title',
            leadsIds.length
          ),
          cancelTitle: this.$t('sparkfunnels.lead_manager.list.actions.delete_leads.confirm_modal.cancel_title'),
          contentTitle: this.$tc(
            'sparkfunnels.lead_manager.list.actions.delete_leads.confirm_modal.title',
            leadsIds.length
          ),
          contentDescription: this.$tc(
            'sparkfunnels.lead_manager.list.actions.delete_leads.confirm_modal.description',
            leadsIds.length,
            { name: this.selectedLeads[0].name }
          ),
          variant: 'cherry',
          icon: 'trash-alt',
        });
        if (shouldDelete) {
          this.isLoading = true;
          this.deleteLeads(leadsIds)
            .then(() => {
              ToastHelper.successMessage(
                this.$tc('sparkfunnels.lead_manager.list.actions.delete_leads.success', leadsIds.length)
              );
              TrackingHelper.trackLeadsRemoval(leadsIds.length, false);
            })
            .catch(() =>
              ToastHelper.dangerMessage(
                this.$tc('sparkfunnels.lead_manager.list.actions.delete_leads.error', leadsIds.length)
              )
            )
            .finally(() => {
              this.isLoading = false;
              this.selectedLeads = [];
            });
        }
      }
      this.loading = '';
    },
    clearSelection() {
      if (this.$refs.table) this.$refs.table.clearSelected();
    },
    async showLeadCard() {
      this.isLoading = true;
      try {
        const {
          data: { limit_leads },
        } = await heimdallService.getFeatureLimit();
        this.theLimite = limit_leads || 0;
        const { funnels, leads } = await this.getFunnelsLimit();
        this.theAmount = leads || 0;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    changePage(page) {
      this.currentPage = page;
      this.loading = 'paging';
      this.fetchLeads();
    },
  },
  async created() {
    this.searchLeads = debounce(queryStr => {
      this.query = queryStr;
      this.currentPage = 1;
      this.clearSelection();
      this.fetchLeads();
      this.selectedLeads = [];
    }, 300);
    await this.showLeadCard();
    await this.fetchLeads();
  },
  beforeDestroy() {
    this.clearSelection();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/_page_title.scss';

.title {
  @include title();
}

/deep/.pagination {
  > .col {
    &:first-child,
    &:last-child {
      display: none;
    }
  }
}

@media screen and (max-width: $screen-sm) {
  #lead-list-section {
    padding: 20px !important;
    &.fix-overflow {
      overflow-x: auto;
    }
  }
}

.no-results {
  max-width: 300px;
}

.auto-pilot-banner {
  margin-bottom: 24px;
}
</style>
