<template>
  <div class="flex-1 ">
    <b-card
      class="h-100 notify-card transition flex-1 shadow-sm rounded-sm justify-content-center align-items-center"
      :class="{ selected: selected }"
      body-class="p-4 d-flex flex-column flex-md-row w-100"
      @click="$emit('clicked', type)"
    >
      <hs-icon
        class="transition d-flex justify-content-center h2 font-weight-light mb-2 mr-3"
        :class="{ 'text-primary': selected, 'text-secondary': !selected }"
        :icon="icon"
        variant="light"
      />
      <div class="text-center text-md-left">
        <h5 class="card-title lh-125 font-weight-bold mb-2">
          {{ $t(`sparkfunnels.lead_manager.leads_import.step_choose_type.card.${type}.title`) }}
        </h5>
        <h6 class="card-description lh-150 font-weight-normal mb-0">
          {{ $t(`sparkfunnels.lead_manager.leads_import.step_choose_type.card.${type}.description`) }}
        </h6>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    selectedOption: {
      type: Object,
    },
    type: {
      required: true,
      type: String,
    },
  },
  computed: {
    icon() {
      return this.type === 'external' ? 'cloud-upload' : 'exchange-alt';
    },
    selected() {
      return this.selectedOption.type === this.type;
    },
  },
};
</script>

<style lang="scss" scoped>
.notify-card {
  border: 2px solid $grey-17;
  box-sizing: border-box;
  cursor: pointer;
}

.transition {
  transition: all 0.5s ease;
}

.card-title {
  letter-spacing: -0.233333px;
}

.card-description {
  color: $grey-30;
}

.lh-125 {
  line-height: 125%;
}

.lh-150 {
  line-height: 150%;
}

.selected {
  border: 2px solid $cyan;
}
</style>
