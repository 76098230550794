<template>
  <div class="d-flex flex-column align-items-center pt-4 pb-5 pb-md-4 px-3 px-md-5">
    <h3 class="text-dark text-center font-weight-bold mb-5 pb-md-2 title ">
      {{ $t('sparkfunnels.lead_manager.leads_import.step_choose_type.title') }}
    </h3>
    <div class="d-flex flex-column flex-lg-row pb-md-2">
      <card class="mb-2 mb-lg-0 mr-lg-3" :selected-option="selectedOption" type="external" @clicked="selectOption" />
      <card class="mt-1 mt-lg-0 mb-lg-0" :selected-option="selectedOption" type="funnel" @clicked="selectOption" />
    </div>
    <div
      class="w-100 div-buttons d-flex flex-column flex-md-row-reverse justify-content-center align-items-center pt-4 pt-md-5 mt-md-4"
    >
      <hs-button
        class="mb-2 mb-md-0 custom-button"
        variant="primary"
        @click="$emit('proceed')"
        :disabled="!selectedOption.type"
      >
        {{ $t('sparkfunnels.lead_manager.leads_import.step_choose_type.buttons.confirm') }}
      </hs-button>
      <hs-button class="mr-md-2 custom-button" variant="link" @click="$emit('recede')">
        {{ $t('sparkfunnels.lead_manager.leads_import.step_choose_type.buttons.cancel') }}
      </hs-button>
    </div>
  </div>
</template>

<script>
import Card from './Card';

export default {
  components: {
    Card,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      selectedOption: { type: this.data.selectedType },
    };
  },
  methods: {
    selectOption(option) {
      this.selectedOption.type = option;
      this.$emit('change', { selectedType: this.selectedOption.type });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/_custom_button.scss';

@include div-custom-buttons;

.title {
  line-height: 115%;
}
</style>
