<template>
  <div class="details-wrapper h-100 overflow-auto d-flex position-fixed flex-column bg-white shadow-sm ml-5">
    <LeadImage :email="lead.email" class="mb-3">
      <hs-button id="close-btn" variant="link" class="position-absolute" @click="$emit('close')">
        <hs-icon icon="arrow-left" class="text-dark h3 font-weight-light" />
      </hs-button>
    </LeadImage>
    <div class="mx-3">
      <div class="d-flex align-items-center justify-content-between mb-5">
        <h4 class="text-capitalize font-weight-bold text-break">{{ lead.name }}</h4>
        <hs-button id="delete-btn" variant="link" @click="$emit('delete', { id: lead.id })">
          <hs-icon icon="trash-alt" class="text-grey h3 mb-0 font-weight-light" />
        </hs-button>
      </div>
      <div class="mb-5">
        <h6 class="info-title font-weight-bold text-uppercase mb-2">
          {{ $t('sparkfunnels.lead_manager.list.details.lead_data.title') }}
        </h6>
        <ul class="list-unstyled d-flex flex-column">
          <li v-for="(data, index) in leadData" :key="index" class="d-flex align-items-center mb-2">
            <hs-icon class="info-icon mr-2" :icon="data.icon" />
            <p class="info-value mb-0 text-break">{{ data.value }}</p>
          </li>
        </ul>
      </div>
      <div class="mb-2">
        <h6 class="info-title font-weight-bold text-uppercase mb-3">
          {{ $t('sparkfunnels.lead_manager.list.details.lead_activities.title') }}
        </h6>
        <hs-card-analytics
          class="mb-3 analytics-card"
          v-for="(activity, index) in leadActivities"
          :key="index"
          :label="activity.time"
          :value="activity.date"
          :icon="activity.icon"
          :symbol="activity.text"
        />
      </div>
      <hs-button
        id="back-btn"
        variant="link"
        class="d-block d-sm-none w-100 text-center mb-5 pb-5"
        @click="$emit('close')"
        >{{ $t('sparkfunnels.lead_manager.list.details.back_to_list') }}</hs-button
      >
    </div>
  </div>
</template>

<script>
import LeadImage from './LeadImage.vue';
import { format } from '@/shared/helpers/dates';

export default {
  components: {
    LeadImage,
  },
  props: {
    lead: {
      type: Object,
    },
  },
  computed: {
    leadData() {
      return [
        {
          icon: 'envelope',
          value: this.lead.email,
        },
        {
          icon: 'phone',
          value: this.lead.phone || '-',
        },
      ];
    },
    leadActivities() {
      return [
        {
          icon: 'user-clock',
          text: this.$t('sparkfunnels.lead_manager.list.details.lead_activities.created_at'),
          date: format(this.lead.created_at, this.$t('date.format')),
          time: `${this.$t('sparkfunnels.lead_manager.list.details.lead_activities.activity_time', {
            time: format(this.lead.created_at, this.$t('hour.format')),
          })}`,
        },
        {
          icon: 'clock',
          text: this.$t('sparkfunnels.lead_manager.list.details.lead_activities.updated_at'),
          date: format(this.lead.updated_at, this.$t('date.format')),
          time: `${this.$t('sparkfunnels.lead_manager.list.details.lead_activities.activity_time', {
            time: format(this.lead.updated_at, this.$t('hour.format')),
          })}`,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.details-wrapper {
  width: calc(100% - 63px);
  right: 0;
  min-height: 100%;
  z-index: 2;
  top: 0;
}

.info-title,
.info-icon {
  color: $grey-25;
}

.info-value {
  color: $grey-30;
}

#close-btn {
  top: 22px;
  left: 6px;
  z-index: 1;
}

/deep/ .analytics-card {
  i {
    font-size: 27px;
  }

  span {
    font-size: 12px;

    &:first-child {
      margin-bottom: 0.25rem;
    }
  }

  h3 {
    font-size: 16px;
  }
}

@media only screen and (min-width: $screen-bs-sm) {
  .details-wrapper {
    max-width: 330px;
  }
}

@media only screen and (min-width: $screen-bs-xl) {
  .details-wrapper {
    position: initial !important;
    min-width: 332px;
    height: auto !important;
  }
}
</style>
