<template>
  <div
    class="w-100 img-div d-flex justify-content-center align-items-center overflow-hidden position-relative"
    :style="style"
  >
    <img
      class="rounded-circle image"
      :src="style.src"
      ref="imgRef"
      width="100"
      height="100"
      @error="$refs.imgRef.src = imageDefault"
    />
  </div>
</template>

<script>
import { avatarService } from '@/services';
import imageDefault from '@/assets/images/lead_manager/default.png';

export default {
  props: {
    email: {
      required: true,
    },
  },
  computed: {
    style() {
      const src = avatarService.fromGravatar({ email: this.email });
      return {
        src,
        '--bg-url': `url(${src}), url(${imageDefault})`,
      };
    },
    imageDefault() {
      return imageDefault;
    },
  },
};
</script>

<style lang="scss" scoped>
.img-div {
  min-height: 150px;
  z-index: 0;
  background-color: rgba($white, 0.3);

  &:before {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba($white, 0.3), rgba($white, 0.3)), var(--bg-url);
    background-size: 100% auto;
    filter: blur(4px);
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    z-index: -1;
    background-position: center;
  }
}
</style>
