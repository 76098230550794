<template>
  <b-modal
    :id="modalId"
    size="lg"
    hide-footer
    :title="$t('sparkfunnels.lead_manager.leads_import.modal_title')"
    centered
    body-class="p-0"
    @close="hide"
    @hidden="reset"
  >
    <b-tabs v-model="tabIndex" nav-class="d-none" nav-wrapper-class="d-none">
      <b-tab v-for="(tab, index) in tabs" :key="index" lazy>
        <component
          v-bind:is="tab.component"
          :data="dataInEditing"
          @change="changeData"
          @proceed="proceed"
          @recede="recede"
        />
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import ChooseType from './ChooseType';
import { CheckEmails, ChooseFunnel, SecurityWarn, UploadFile } from './ExternalType';
import FromSourceToTargetFunnel from './FunnelType/FromSourceToTargetFunnel';
import Finished from './Finished';

const importTypes = Object.freeze({
  funnel: 'funnel',
  external: 'external',
});

export default {
  computed: {
    tabsFromImportType() {
      return this.dataInEditing.selectedType === importTypes.external
        ? [
            { component: SecurityWarn },
            { component: ChooseFunnel },
            { component: UploadFile },
            { component: CheckEmails },
          ]
        : [{ component: FromSourceToTargetFunnel }];
    },
    modalId: () => 'leads-import-modal',
    tabs() {
      return [{ component: ChooseType }, ...this.tabsFromImportType, { component: Finished }];
    },
  },
  data() {
    return {
      dataInEditing: {},
      tabIndex: 0,
    };
  },
  methods: {
    changeData(newData) {
      this.dataInEditing = { ...this.dataInEditing, ...newData };
    },
    hide() {
      this.$bvModal.hide(this.modalId);
    },
    proceed() {
      if (this.tabIndex === this.tabs.length - 1) {
        this.hide();
        return;
      }
      this.tabIndex++;
    },
    recede() {
      if (this.tabIndex === 0) this.hide();
      else this.tabIndex--;
    },
    reset() {
      this.tabIndex = 0;
      this.dataInEditing = {};
    },
  },
};
</script>
