<template>
  <div>
    <hs-table
      ref="hstable"
      id="lead-manager-table"
      :responsive="true"
      :hover="true"
      :fields="fields"
      :items="items"
      :select-mode="'multi'"
      :selectable="true"
      :clickable="true"
      @selectedItems="items => $emit('selected', items)"
      @clickedItem="item => $emit('clicked', item)"
      :busy="loading === 'removing' || loading === 'paging'"
    >
      <template slot="name" slot-scope="row">
        <span class="text-capitalize custom-text-table font-weight-bold">{{ row.item.name }}</span>
      </template>
      <template slot="email" slot-scope="row">
        <span class="custom-text-table">{{ row.item.email }} </span>
      </template>
      <template slot="phone" slot-scope="row">
        <span class="custom-text-table">{{ row.item.phone ? row.item.phone : '-' }} </span>
      </template>
      <template slot="action" slot-scope="row">
        <div class="w-100 d-flex justify-content-center">
          <hs-button variant="link" @click="$emit('delete', { id: row.item.id })" :loading="loading === 'removing'">
            <hs-icon icon="trash-alt" class="h4 mb-0 font-weight-light" />
          </hs-button>
        </div>
      </template>
      <template slot="more">
        <div class="shadow-sm d-flex align-items-center justify-content-center pl-2 pr-3">
          <hs-icon icon="chevron-right" />
        </div>
      </template>
    </hs-table>
  </div>
</template>

<script>
export default {
  name: 'leads-table',
  props: {
    items: {
      default: () => [{}, {}, {}, {}],
    },
    loading: {
      type: String,
      default: '',
    },
  },
  watch: {
    onChange(value) {
      this.changePage(value);
    },
  },
  computed: {
    fields() {
      return [
        { key: 'check', label: '', thClass: 'th-checkbox' },
        {
          key: 'name',
          label: this.$t('sparkfunnels.lead_manager.list.table.fields.name'),
          sortable: true,
        },
        {
          key: 'email',
          label: this.$t('sparkfunnels.lead_manager.list.table.fields.email'),
          sortable: true,
        },
        {
          key: 'phone',
          label: this.$t('sparkfunnels.lead_manager.list.table.fields.phone'),
          sortable: true,
        },
        {
          key: 'action',
          label: '',
          sortable: false,
        },
        {
          key: 'more',
          label: '',
          sortable: false,
        },
      ];
    },
  },
  methods: {
    clearSelected() {
      if (this.$refs.hstable) this.$refs.hstable.clearSelected();
    },
    changePage(page) {
      this.$emit('changePage', page);
    },
  },
};
</script>

<style lang="scss">
#lead-manager-table {
  .custom-text-table {
    font-size: 16px;
    color: $grey;
  }
  th {
    font-size: 14px;
  }
  td:last-child {
    padding: 0;
    vertical-align: top;
    float: right;
    > div {
      min-height: 69px;
    }
  }
}
</style>
