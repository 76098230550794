<template>
  <div class="d-flex flex-column flex-lg-row">
    <media-viewer
      class="flex-1"
      :media="{
        type: 'Video',
        host: $t('sparkfunnels.lead_manager.leads_import.step_upload_file.collapse.sample_video.video_host'),
        host_key: $t('sparkfunnels.lead_manager.leads_import.step_upload_file.collapse.sample_video.embed_link'),
      }"
    />
    <div class="pt-5 pl-lg-4 flex-1 d-flex flex-row">
      <hs-icon class="text-primary pr-2 icon" icon="question-circle" variant="light" />
      <div class="pl-1 d-flex flex-column">
        <h5 class="text-dark font-weight-bold pb-3 lh-125 how-to-fill">
          {{ $t('sparkfunnels.lead_manager.leads_import.step_upload_file.collapse.sample_video.how_to_fill') }}
        </h5>
        <h5 class="lh-150 how-to-fill-response">
          {{ $t('sparkfunnels.lead_manager.leads_import.step_upload_file.collapse.sample_video.how_to_fill_response') }}
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import MediaViewer from '@/components/MediaViewer';

export default {
  components: {
    MediaViewer,
  },
};
</script>

<style lang="scss" scoped>
/deep/.icon {
  font-size: 1.5rem;
}

.how-to-fill {
  letter-spacing: -0.233333px;
}
.how-to-fill-response {
  color: $grey-32;
  max-width: 274px;
}
</style>
