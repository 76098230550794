<template>
  <div class="d-flex flex-column align-items-center pt-4 pb-5 pb-md-4 px-3 px-lg-0">
    <hs-icon class="d-none d-lg-block pt-4 text-primary icon" icon="bell" variant="light" />
    <div v-if="invalidFile" class="text-center">
      <h3 class="text-dark font-weight-bold pt-lg-3 pb-3 title lh-125">
        {{ $t('sparkfunnels.lead_manager.leads_import.step_check_email.information.invalid_file_title') }}
      </h3>
      <h5 class="mb-5 lh-125">
        {{ $t('sparkfunnels.lead_manager.leads_import.step_check_email.information.invalid_file_description') }}
      </h5>
    </div>
    <div v-else-if="errorReadingTheFile" class="text-center">
      <h3 class="text-dark font-weight-bold pt-lg-3 pb-3 title lh-125">
        {{ $t('sparkfunnels.lead_manager.leads_import.step_check_email.information.corrupted_file_title') }}
      </h3>
      <h5 class="mb-5 lh-125">
        {{ $t('sparkfunnels.lead_manager.leads_import.step_check_email.information.corrupted_file_description') }}
      </h5>
    </div>
    <div v-else-if="emptyFile" class="text-center">
      <h3 class="text-dark font-weight-bold pt-lg-3 pb-3 title lh-125">
        {{ $t('sparkfunnels.lead_manager.leads_import.step_check_email.information.empty_file_title') }}
      </h3>
      <h5 class="mb-5 lh-125">
        {{ $t('sparkfunnels.lead_manager.leads_import.step_check_email.information.empty_file_description') }}
      </h5>
    </div>
    <div v-else-if="isImporting" class="text-center">
      <h3 class="text-dark font-weight-bold pt-lg-3 pb-3 title lh-125">
        {{ $t('sparkfunnels.lead_manager.leads_import.step_check_email.information.is_importing_title') }}
      </h3>
      <h5 class="lh-125">
        {{ $t('sparkfunnels.lead_manager.leads_import.step_check_email.information.is_importing_description') }}
      </h5>
      <hs-loading />
    </div>
    <template v-else>
      <div class="w-100 information text-center">
        <h3 class="text-dark font-weight-bold pt-lg-3 pb-3 title lh-125">
          {{ $t('sparkfunnels.lead_manager.leads_import.step_check_email.information.title') }}
        </h3>
        <h5 class="mb-0 mb-lg-4 lh-125">
          {{ $t('sparkfunnels.lead_manager.leads_import.step_check_email.information.description') }}
        </h5>
      </div>
      <div class="w-100 d-flex justify-content-center px-0 py-5 invalid-fields">
        <div v-if="isReading" class="d-flex flex-column">
          <hs-placeholder animated width="50%" height="14px" class="mb-2" />
          <hs-placeholder animated width="100%" height="14px" class="mb-2" />
          <hs-placeholder animated width="75%" height="14px" />
        </div>
        <div v-else class="w-100">
          <label
            for="invalid-inputs"
            class="h6 mb-0 font-weight-bold lh-150 invalid-inputs-label"
            v-html="
              $tc(
                'sparkfunnels.lead_manager.leads_import.step_check_email.invalid_inputs_label',
                invalidEmails.length,
                {
                  size: invalidEmails.length,
                }
              )
            "
          />
          <hs-multiselect
            id="invalid-inputs"
            :options="invalidEmails"
            label="email"
            :searchable="false"
            :show-labels="false"
            :placeholder="
              $t('sparkfunnels.lead_manager.leads_import.step_check_email.information.invalid_inputs_placeholder')
            "
          />
        </div>
      </div>
    </template>

    <div
      v-if="!isImporting"
      class="w-100 div-buttons d-flex flex-column flex-md-row-reverse justify-content-center align-items-center pt-lg-4"
    >
      <hs-button
        id="confirm-button"
        v-if="file && !invalidFile && !emptyFile"
        class="mb-2 mb-md-0 custom-button"
        variant="primary"
        @click="leadsImport"
      >
        {{ $t('sparkfunnels.lead_manager.leads_import.step_check_email.buttons.confirm') }}
      </hs-button>
      <hs-button id="cancel-button" class="mr-md-2 custom-button" variant="link" @click="$emit('recede')">
        {{ $t('sparkfunnels.lead_manager.leads_import.step_check_email.buttons.cancel') }}
      </hs-button>
    </div>
  </div>
</template>

<script>
import { email as isValidEmail } from 'vuelidate/lib/validators';
import Papa from 'papaparse';
import { leadApi } from '@/services';
import { hsLoading } from '@/components';
import ToastHelper from '@/shared/helpers/toast';

export default {
  components: {
    hsLoading,
  },
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    fields() {
      return {
        EMAIL: this.$t('sparkfunnels.lead_manager.leads_import.step_check_email.validation.second_column'),
        NAME: this.$t('sparkfunnels.lead_manager.leads_import.step_check_email.validation.first_column'),
        PHONE: this.$t('sparkfunnels.lead_manager.leads_import.step_check_email.validation.third_column'),
      };
    },
  },
  data() {
    return {
      file: this.data.file,
      emptyFile: false,
      errorReadingTheFile: false,
      validInput: new Map(),
      invalidInput: new Map(),
      invalidEmails: [],
      invalidFile: false,
      isImporting: false,
      isReading: false,
    };
  },
  methods: {
    checkIfProceed() {
      if (this.invalidEmails.length === 0 && this.validInput.size > 0) this.leadsImport();
    },
    complete() {
      this.isReading = false;
      this.invalidEmails = Array.from(this.invalidInput.keys()).map(email => ({ email, $isDisabled: true }));
      this.$emit('change', {
        validInputs: Array.from(this.validInput.values()),
      });
      this.emptyFile = this.validInput.size === 0 && this.invalidInput.size === 0;

      this.checkIfProceed();
    },
    error() {
      this.errorReadingTheFile = true;
    },
    leadsImport() {
      this.isImporting = true;
      const payload = {
        funnelId: this.data.funnel.id,
        leads: Array.from(this.validInput.values()),
      };
      leadApi
        .import(payload)
        .then(() => this.$emit('proceed'))
        .finally(() => (this.isImporting = false))
        .catch(() =>
          ToastHelper.dangerMessage(this.$t('sparkfunnels.lead_manager.leads_import.step_check_email.import_error'))
        );
    },
    isValidFile(fields) {
      return fields[0] === this.fields.NAME && fields[1] === this.fields.EMAIL && fields[2] === this.fields.PHONE;
    },
    validateSpreadsheetRow({ data, meta }, parser) {
      if (!this.isValidFile(meta.fields)) {
        this.invalidFile = true;
        parser.abort();
      }

      const email = this.cleanString(data[this.fields.EMAIL]),
        name = this.cleanString(data[this.fields.NAME]),
        phone = this.cleanString(data[this.fields.PHONE]);

      if (name && email && isValidEmail(email)) {
        this.validInput.set(email, { email, name, phone });
      } else {
        this.invalidInput.set(email, { email, name, phone });
      }
    },
    cleanString(str) {
      try {
        return str.replace(/'|"|\r\n|\n|\r/gi, '').trim();
      } catch (error) {
        return '';
      }
    },
  },
  created() {
    this.isReading = true;
    Papa.parse(this.file, {
      skipEmptyLines: true,
      fastMode: true,
      header: true,
      step: this.validateSpreadsheetRow,
      complete: this.complete,
      error: this.error,
    });
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/_custom_button.scss';

@include div-custom-buttons;

.icon {
  font-size: 3rem;
}

.invalid-fields {
  background-color: $white;
}

/deep/.invalid-inputs-label {
  color: $grey-32;
  > span {
    color: #e5402b;
  }
}

@media only screen and (min-width: $screen-bs-lg) {
  .information {
    width: 65% !important;
  }
  .invalid-fields {
    background-color: $grey-10;
    > div {
      width: 75% !important;
    }
  }
}
</style>
