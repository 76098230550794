<template>
  <div class="d-flex flex-column align-items-center pt-4 pb-5 pb-md-4 px-3 px-md-5">
    <h3 class="w-75 text-dark text-center font-weight-bold mb-5 pb-md-2 title">
      {{ $t('sparkfunnels.lead_manager.leads_import.step_choose_funnel.title') }}
    </h3>
    <hs-loading v-if="isLoading" />
    <div v-else class="w-100">
      <label for="select-funnel" class="font-weight-bold mb-0 lh-150">
        {{ $t('sparkfunnels.lead_manager.leads_import.step_choose_funnel.input_label') }}
      </label>
      <hs-multiselect
        id="select-funnel"
        class="w-100"
        v-model="selectedFunnel"
        label="name"
        :options="funnels"
        :searchable="false"
        :allow-empty="false"
        :show-labels="false"
        :placeholder="$t('sparkfunnels.lead_manager.leads_import.step_choose_funnel.input_placeholder')"
        @input="value => $emit('change', { funnel: value })"
      />
      <ul class="mb-0 pl-3 pt-3">
        <li class="text-cherry">
          <h6
            class="note-text lh-150"
            v-html="$t('sparkfunnels.lead_manager.leads_import.step_choose_funnel.description')"
          />
        </li>
      </ul>
      <div
        class="w-100 div-buttons d-flex flex-column flex-md-row-reverse justify-content-center align-items-center pt-5 mt-md-5"
      >
        <hs-button
          class="mb-2 mb-md-0 custom-button"
          variant="primary"
          @click="$emit('proceed')"
          :disabled="!selectedFunnel"
        >
          {{ $t('sparkfunnels.lead_manager.leads_import.step_choose_funnel.buttons.confirm') }}
        </hs-button>
        <hs-button class="mr-md-2 custom-button" variant="link" @click="$emit('recede')">
          {{ $t('sparkfunnels.lead_manager.leads_import.step_choose_funnel.buttons.cancel') }}
        </hs-button>
      </div>
    </div>
  </div>
</template>

<script>
import ToastHelper from '@/shared/helpers/toast';
import { funnelApi } from '@/services';
import { hsLoading } from '@/components';

export default {
  components: {
    hsLoading,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      perPage: 50,
      funnels: [],
      selectedFunnel: this.data.funnel,
    };
  },
  methods: {
    fetchFunnels() {
      this.isLoading = true;
      const params = {
        perPage: this.perPage,
        currentPage: 1,
      };
      funnelApi
        .getFunnels(params)
        .then(({ data }) => {
          this.funnels = data.funnels;
        })
        .catch(() => ToastHelper.dangerMessage(this.$t('sparkfunnels.funnels.list.toast.request_error')))
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.fetchFunnels();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/_custom_button.scss';

@include div-custom-buttons;
.note-text {
  color: $grey-32 !important;
}
</style>
