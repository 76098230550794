<template>
  <div>
    <MButton
      id="import-btn"
      variant="primary"
      @click="importLeads"
      :disabled="disabled"
      :label="$t('sparkfunnels.lead_manager.list.banner.button')"
      :premium="true"
    />
    <LeadsImportModal />
    <hsFunnelsPremiumModal :id="premiumModalId" @click="redirectToSalesPage" />
  </div>
</template>

<script>
import LeadsImportModal from './LeadsImportModal';
import { analyticsService } from '@/services';
import hsFunnelsPremiumModal from '@/sparkfunnels/components/FunnelsPremiumModal';
import { mapActions, mapState } from 'vuex';
import MButton from '@/shared/components/MButton.vue';

export default {
  components: {
    LeadsImportModal,
    hsFunnelsPremiumModal,
    MButton,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    ...mapState('auth', ['loggedUser']),
    premiumModalId: () => 'premium-modal',
  },
  methods: {
    ...mapActions('school', ['updateSchool']),
    importLeads() {
      if (!this.$FCL.canFunnelsImportLeads()) {
        this.trackAnalytics();
        this.$bvModal.show(this.premiumModalId);
      } else {
        this.$bvModal.show('leads-import-modal');
      }
    },
    redirectToSalesPage() {
      this.trackAnalytics();
      this.$router.push({ name: 'MyAccountPlans' });
      this.$bvModal.hide(this.premiumModalId);
    },
    async trackAnalytics() {
      analyticsService.track({
        event: 'Paywall displayed',
        props: {
          product: 'Funnels',
          location: 'Lead Manager - Import',
          paywallReason: 'lead import',
        },
      });
      const school = this.selectedSchool;
      school.extra_settings.show_top_bar = 'true';
      await this.updateSchool({ id: school.id, extra_settings: school.extra_settings });
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  background-color: $pale-cyan;
}

.premium-badge {
  color: $yellow-dark;
  background: $yellow-light-02;
  top: -15px;
  right: 5px;
}
</style>
