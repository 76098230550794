<template>
  <div class="d-flex flex-column align-items-center">
    <hs-button class="download-button" icon="arrow-down" @click="downloadFile" />
    <span class="text-center h6 font-weight-bold mb-0 pt-2 lh-150">
      {{ $t('sparkfunnels.lead_manager.leads_import.step_upload_file.collapse.sample_file.load_file') }}
    </span>
    <a
      class="text-center font-weight-bold h6 mt-1 mb-0 pt-2 text-primary lh-150"
      :href="$t('sparkfunnels.lead_manager.leads_import.step_upload_file.collapse.sample_file.link_download')"
      target="_blank"
      ><u>{{ $t('sparkfunnels.lead_manager.leads_import.step_upload_file.collapse.sample_file.file') }}</u></a
    >
    <span
      class="text-center text-secondary pt-5 lh-150 file-link"
      v-html="
        $t('sparkfunnels.lead_manager.leads_import.step_upload_file.collapse.sample_file.by_google_drive', {
          link: $t('sparkfunnels.lead_manager.leads_import.step_upload_file.collapse.sample_file.link'),
        })
      "
    >
    </span>
  </div>
</template>

<script>
export default {
  methods: {
    downloadFile() {
      window.open(
        this.$t('sparkfunnels.lead_manager.leads_import.step_upload_file.collapse.sample_file.link_download')
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.download-button {
  height: 51px;
  width: 51px;
  background-color: #99f2f8;
  border-radius: 50%;
  &:hover {
    background-color: #99f2f8;
  }
  /deep/ i {
    margin-right: 0;
    color: $grey;
  }
}
.file-link {
  font-size: 12px;
  /deep/ a {
    color: $cyan;
    font-weight: bold;
  }
}
</style>
