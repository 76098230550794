<template>
  <div class="tlw-flex tlw-flex-col tlw-pt-6 tlw-pb-12 md:tlw-pb-6 tlw-px-4 md:tlw-px-12">
    <div class="tlw-flex tlw-items-center">
      <hs-icon icon="exclamation-circle" size="24" />

      <div class="tlw-text-2xl tlw-font-bold tlw-ml-2">
        {{ $t('sparkfunnels.lead_manager.leads_import.step_import_warning.title') }}
      </div>
    </div>

    <div
      v-html="$t('sparkfunnels.lead_manager.leads_import.step_import_warning.explanation.intro')"
      class="tlw-text-gray-600 tlw-text-sm tlw-mt-6"
    />

    <div class="tlw-text-gray-600 tlw-text-sm tlw-mt-4">
      <ol class="security-warn__requirements-list">
        <li>
          {{ $t('sparkfunnels.lead_manager.leads_import.step_import_warning.explanation.requirements.req1') }}
        </li>
        <li>
          {{ $t('sparkfunnels.lead_manager.leads_import.step_import_warning.explanation.requirements.req2') }}
        </li>
        <li>
          {{ $t('sparkfunnels.lead_manager.leads_import.step_import_warning.explanation.requirements.req3') }}
        </li>
      </ol>
    </div>

    <div class="tlw-text-gray-600 tlw-text-sm tlw-mt-4">
      {{ $t('sparkfunnels.lead_manager.leads_import.step_import_warning.explanation.import_limit_warn') }}
    </div>

    <hs-checkbox v-model="acceptedImportRequirements" class="tlw-mt-6">
      <div class="tlw-text-gray-900 tlw-text-sm">
        {{ $t('sparkfunnels.lead_manager.leads_import.step_import_warning.accept_requirements') }}
      </div>
    </hs-checkbox>

    <div
      class="tlw-w-full tlw-flex tlw-flex-col md:tlw-flex-row-reverse tlw-justify-center tlw-items-center tlw-pt-6 md:tlw-pt-12 md:tlw-mt-6"
    >
      <hs-button
        class="tlw-mb-2 md:tlw-mb-0"
        variant="primary"
        @click="$emit('proceed')"
        :disabled="!acceptedImportRequirements"
      >
        {{ $t('sparkfunnels.lead_manager.leads_import.step_import_warning.buttons.confirm') }}
      </hs-button>
      <hs-button class="md:tlw-mr-2" variant="link" @click="$emit('recede')">
        {{ $t('sparkfunnels.lead_manager.leads_import.step_import_warning.buttons.cancel') }}
      </hs-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      acceptedImportRequirements: false,
    };
  },
};
</script>

<style lang="scss">
.security-warn__requirements-list {
  list-style-type: decimal;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}
</style>
