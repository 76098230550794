<template>
  <div class="d-flex flex-column align-items-center pt-4 pb-5 pb-md-4 px-3 px-md-5">
    <template v-if="!isImporting">
      <h3 class="title mb-5 text-center font-weight-bold">
        {{ $t('sparkfunnels.lead_manager.leads_import.step_source_target_funnel.title') }}
      </h3>
      <hs-loading v-if="isLoading" />
      <div v-else class="shadow-sm border rounded-lg d-flex flex-column flex-lg-row mb-4">
        <div class="source-wrapper d-flex flex-column p-4 flex-1">
          <p class="funnel-title text-uppercase text-center mb-4">
            {{ $t('sparkfunnels.lead_manager.leads_import.step_source_target_funnel.source_funnel.title') }}
          </p>
          <hs-group
            :label="$t('sparkfunnels.lead_manager.leads_import.step_source_target_funnel.source_funnel.label')"
            label-for="source-funnel"
            label-class="mb-0 font-weight-bold"
            class="mb-1"
          >
            <hs-multiselect
              id="source-funnel"
              class="w-100"
              v-model="sourceFunnel"
              label="name"
              :options="funnels"
              :searchable="false"
              :allow-empty="false"
              :show-labels="false"
              :placeholder="
                $t('sparkfunnels.lead_manager.leads_import.step_source_target_funnel.source_funnel.placeholder')
              "
              @input="sourceFunnelChanged"
            />
          </hs-group>
        </div>
        <div class="position-relative d-flex align-items-center align-items-lg-start justify-content-center">
          <hs-icon
            id="divider-icon"
            icon="long-arrow-down"
            class="position-absolute text-cherry h2 mt-lg-2 font-weight-light"
          />
        </div>
        <div class="d-flex flex-column p-4 flex-1">
          <p class="funnel-title text-uppercase text-center mb-4">
            {{ $t('sparkfunnels.lead_manager.leads_import.step_source_target_funnel.target_funnel.title') }}
          </p>
          <hs-group
            :label="$t('sparkfunnels.lead_manager.leads_import.step_source_target_funnel.target_funnel.label')"
            label-for="target-funnel"
            label-class="mb-0 font-weight-bold"
            class="mb-3"
          >
            <hs-multiselect
              id="target-funnel"
              class="w-100"
              v-model="targetFunnel"
              label="name"
              :options="availableTargetFunnels"
              :searchable="false"
              :allow-empty="false"
              :show-labels="false"
              :placeholder="
                $t('sparkfunnels.lead_manager.leads_import.step_source_target_funnel.target_funnel.placeholder')
              "
            />
          </hs-group>
          <ul class="pl-3">
            <li class="text-cherry">
              <h6 class="note-text lh-150">
                {{ $t('sparkfunnels.lead_manager.leads_import.step_source_target_funnel.target_funnel.note') }}
              </h6>
            </li>
          </ul>
        </div>
      </div>
      <p v-if="sourceFunnel && targetFunnel" class="description mt-3 mb-5 text-center">
        {{
          $t('sparkfunnels.lead_manager.leads_import.step_source_target_funnel.description', {
            source: sourceFunnel.name,
            target: targetFunnel.name,
          })
        }}
      </p>
      <div
        class="div-buttons mt-4 w-100 div-buttons d-flex flex-column flex-md-row-reverse justify-content-center align-items-center"
      >
        <hs-button
          id="ok-btn"
          :disabled="!sourceFunnel || !targetFunnel"
          class="custom-button mb-2 mb-md-0"
          variant="primary"
          @click="importLeads"
        >
          {{ $t('sparkfunnels.lead_manager.leads_import.step_source_target_funnel.buttons.confirm') }}
        </hs-button>
        <hs-button id="cancel-btn" class="custom-button mr-md-2" variant="link" @click="$emit('recede')">
          {{ $t('sparkfunnels.lead_manager.leads_import.step_source_target_funnel.buttons.cancel') }}
        </hs-button>
      </div>
    </template>
    <div v-else class="text-center">
      <h3 class="text-dark font-weight-bold pt-lg-3 pb-3 title lh-125">
        {{ $t('sparkfunnels.lead_manager.leads_import.step_source_target_funnel.actions.import_leads.loading.title') }}
      </h3>
      <h5 class="lh-125">
        {{
          $t(
            'sparkfunnels.lead_manager.leads_import.step_source_target_funnel.actions.import_leads.loading.description'
          )
        }}
      </h5>
      <hs-loading />
    </div>
  </div>
</template>

<script>
import ToastHelper from '@/shared/helpers/toast';
import { funnelApi, leadApi } from '@/services';
import { hsLoading } from '@/components';

export default {
  components: {
    hsLoading,
  },
  data() {
    return {
      sourceFunnel: null,
      targetFunnel: null,
      funnels: [],
      isLoading: false,
      isImporting: false,
    };
  },
  computed: {
    availableTargetFunnels() {
      return this.sourceFunnel ? this.funnels.filter(funnel => funnel.id !== this.sourceFunnel.id) : this.funnels;
    },
  },
  methods: {
    fetchFunnels() {
      this.isLoading = true;
      funnelApi
        .getFunnels({ perPage: 50, currentPage: 1 })
        .then(({ data }) => (this.funnels = data.funnels))
        .catch(() =>
          ToastHelper.dangerMessage(
            this.$t('sparkfunnels.lead_manager.leads_import.step_source_target_funnel.actions.fetch_funnels.error')
          )
        )
        .finally(() => (this.isLoading = false));
    },
    sourceFunnelChanged(newSourceFunnel) {
      if (newSourceFunnel && this.targetFunnel && newSourceFunnel.id === this.targetFunnel.id) {
        this.targetFunnel = null;
      }
    },
    importLeads() {
      this.isImporting = true;
      leadApi
        .importBetweenFunnels({ destinationId: this.targetFunnel.id, sourceId: this.sourceFunnel.id })
        .then(() => this.$emit('proceed'))
        .catch(() =>
          ToastHelper.dangerMessage(
            this.$t('sparkfunnels.lead_manager.leads_import.step_source_target_funnel.actions.import_leads.error')
          )
        )
        .finally(() => (this.isImporting = false));
    },
  },
  mounted() {
    this.fetchFunnels();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/_custom_button.scss';

@include div-custom-buttons;

.title {
  max-width: 500px;
}

.source-wrapper {
  background-color: $grey-10;
}

.funnel-title {
  color: $grey-30;
  font-size: 12px;
}

.note-text,
.description {
  color: $grey-32 !important;
}

@media only screen and (min-width: $screen-bs-lg) {
  #divider-icon {
    transform: rotate(-90deg);
  }
}
</style>
