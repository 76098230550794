<template>
  <div class="d-flex flex-column align-items-center mb-5 mb-lg-2 pb-5 pb-lg-4">
    <div class="w-100 d-flex flex-column align-items-center position-relative image-content">
      <b-img-lazy fluid class="position-absolute mt-5" src="@/assets/images/lead_manager/social-proof.svg" />
    </div>
    <div class="my-5 pt-4 pt-lg-5 px-3 px-lg-0 text-center data-content">
      <h3 class="text-dark font-weight-bold pt-lg-3 pb-4 title lh-125">
        {{ $t('sparkfunnels.lead_manager.leads_import.step_finished.title') }}
      </h3>
      <h5 class="lh-125 description">
        {{ $t('sparkfunnels.lead_manager.leads_import.step_finished.description') }}
      </h5>
    </div>
    <div
      class="w-100 div-buttons d-flex flex-column flex-md-row-reverse justify-content-center align-items-center pt-0 pt-lg-4 mt-md-5"
    >
      <hs-button class="  mb-md-0 custom-button justify-content-center" variant="primary" @click="$emit('proceed')">
        {{ $t('sparkfunnels.lead_manager.leads_import.step_finished.buttons.confirm') }}
      </hs-button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/_custom_button.scss';

@include div-custom-buttons;

.custom-button {
  min-width: 80%;
}

.description {
  letter-spacing: -0.2px;
  color: $grey-32;
}

.image-content {
  background-color: $pale-cyan;
  min-height: 229px;
}

@media only screen and (min-width: $screen-bs-md) {
  .custom-button {
    min-width: auto;
  }

  .data-content {
    width: 60% !important;
  }
}
</style>
