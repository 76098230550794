<template>
  <div class="d-flex flex-column custom-content">
    <div
      id="uploader"
      class="py-5 d-flex flex-column justify-content-center align-items-center border-secondary"
      @dragover.prevent
      @drop.prevent="onFile"
    >
      <label id="file-select-btn" class="mb-0 text-center">
        <div class="bg-white d-flex align-items-center justify-content-center rounded-circle upload-icon">
          <div class="d-flex align-items-center justify-content-center rounded-circle">
            <hs-icon icon="arrow-up" />
          </div>
        </div>
        <input id="input-file" type="file" accept=".csv" class="d-none" @input="onFile" />
      </label>
      <div class="w-100 px-4 px-lg-0 text-center import-info">
        <div v-if="!file">
          <h6 class="py-2 text-dark font-weight-bold lh-150">
            {{ $t('sparkfunnels.lead_manager.leads_import.step_upload_file.collapse.drop_file.import_spreadsheet') }}
          </h6>
          <h6 class="upload-info">
            {{ $t('sparkfunnels.lead_manager.leads_import.step_upload_file.collapse.drop_file.upload_info') }}
          </h6>
        </div>
        <div v-else>
          <h6 class="pt-2 pb-3 text-dark font-weight-bold lh-150">
            {{ $t('sparkfunnels.lead_manager.leads_import.step_upload_file.collapse.drop_file.upload_success') }}
          </h6>
          <div class="d-flex flex-row justify-content-center">
            <h6 class="text-primary text-truncate flex-2">
              <u>{{ file.name }}</u>
            </h6>
            <div class="d-flex flex-row flex-1">
              <h6 class="px-2 text-secondary lh-150 text-truncate">{{ formatSize(file.size) }}</h6>
              <div id="remove-file" class="d-flex align-items-center" @click="removeFile">
                <hs-icon class="remove-icon" icon="times" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToastHelper from '@/shared/helpers/toast';
import { formatSize } from '@/shared/helpers/size';

export default {
  props: {
    loadedFile: {
      type: File,
    },
  },
  data() {
    return {
      file: this.loadedFile,
    };
  },
  methods: {
    onFile(e) {
      const file = e.dataTransfer ? e.dataTransfer.files[0] : e.srcElement.files[0];
      if (file.size > this.$t('sparkfunnels.lead_manager.leads_import.step_upload_file.collapse.drop_file.max_size')) {
        ToastHelper.dangerMessage(
          this.$t('sparkfunnels.lead_manager.leads_import.step_upload_file.collapse.drop_file.size_greater', {
            size: this.formatSize(
              this.$t('sparkfunnels.lead_manager.leads_import.step_upload_file.collapse.drop_file.max_size')
            ),
          })
        );
      } else {
        const normalizedFile = file ? new File([file], file.name.split(' ').join('-'), { type: file.type }) : null;
        this.file = normalizedFile;
        this.$emit('uploaded', normalizedFile);
      }
      e.target.value = '';
    },
    removeFile() {
      this.file = null;
      this.$emit('uploaded', null);
    },
    formatSize,
  },
};
</script>

<style lang="scss" scoped>
.custom-content {
  border: 2px dashed $grey-20;
  background-color: $pale-cyan;
}

#file-select-btn {
  cursor: pointer;
}

.upload-icon {
  height: 51px;
  width: 51px;
  > div {
    background-color: #99f2f8;
    height: 37px;
    width: 37px;
  }
}

.remove-icon {
  color: #e42b2b;
  cursor: pointer;
}

.upload-info {
  color: $grey-32;
}

@media only screen and (min-width: $screen-bs-lg) {
  .import-info {
    width: 35% !important;
  }
}
</style>
