/**
 * Formats byte size to human readable format
 * @param {Number} bytes total size in bytes
 * @param {Number} decimals decimal places of formatted string
 * @param {Boolean} kib if formatted string should display in base-10 or base-2, defaults to base-10
 * @returns formatted string
 */
export const formatSize = (bytes, decimals, kib) => {
  kib = kib || false;
  if (bytes === 0) return '0 Bytes';
  if (isNaN(parseFloat(bytes)) && !isFinite(bytes)) return 'Not an number';
  const k = kib ? 1024 : 1000;
  const dm = decimals != null && !isNaN(decimals) && decimals >= 0 ? decimals : 2;
  const sizes = kib
    ? ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB', 'BiB']
    : ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB', 'BB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
