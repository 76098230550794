<template>
  <section class="page-title">
    <section class="d-flex flex-column">
      <slot name="title"></slot>
      <slot name="subtitle"></slot>
    </section>
    <section class="page-title-actions">
      <slot name="actions"></slot>
    </section>
  </section>
</template>

<script>
export default {
  name: 'hsPageTitle',
};
</script>
